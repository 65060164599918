import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: 'AIzaSyBT9T_2RrDD7cv6DrME5E4u8axvHU-oPoI',
  authDomain: 'et-ai-chatbot.firebaseapp.com',
  projectId: 'et-ai-chatbot',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };
